import { homeBanners } from "../types";

const initialState = { en: [], fr: [] };

const homeBannersReducer = (state = initialState, action) => {
  switch (action.type) {
    case homeBanners.SET_GHANA_BANNERS:
      return {
        en: [
          {
            img: "/images/homeBanners/ghana/crbt/crbt.jpeg",
            url: "https://callertunez.mtn.com.gh/crbt-web-portal/home?lang=en",
          },
          {
            img: "/images/homeBanners/ghana/gameloft/gameloft_banner_640x400.png",
            url: "https://gameworld.mtn.com.gh/landing/landing.php?adid=436684&product=4164",
          },
        ],
        fr: [],
      };
    case homeBanners.SET_ZAMBIA_BANNERS:
      return {
        en: [
          // {
          //   img: "/images/homeBanners/zambia/gameloft/MTN-Play-CRBT-Gameloft-640x400px-Web-Banner-5.jpg",
          //   url: "http://wapshop.gameloft.com/mtn_zm/landing.php",
          // },
          {
            img: "/images/homeBanners/zambia/gameloft/1.png",
            url: "http://www.mtn-zm-webfun.com/Gameloft/Gameloft-24-No-26001220000008299-web?trxId={trx_id}",
          },
        ],
        fr: [],
      };
    case homeBanners.SET_CONGO_BANNERS:
      return {
        en: [
          {
            img: "/images/homeBanners/placeholder.png",
            url: "https://google.com",
          },
        ],
        fr: [
          {
            img: "/images/homeBanners/congo/Artwork_MTN_ZIK_640x400px.jpg",
            url: "http://zik.mtncongo.net:8091/selfcare/#/",
          },
        ],
      };
    case homeBanners.SET_CAMEROON_BANNERS:
      return {
        en: [
          // {
          //   img: "/images/homeBanners/cameroon/640x400-NBA.jpg",
          //   url: "/",
          // },
          {
            img: "/images/homeBanners/cameroon/640x400-Livefootball.jpg",
            url: "/",
          },
          {
            img: "/images/homeBanners/cameroon/640x400-WWE.jpg",
            url: "/",
          },
        ],
        fr: [
          // {
          //   img: "/images/homeBanners/cameroon/640x400-NBA.jpg",
          //   url: "/",
          // },
          {
            img: "/images/homeBanners/cameroon/640x400-Livefootball.jpg",
            url: "/",
          },
          {
            img: "/images/homeBanners/cameroon/640x400-WWE.jpg",
            url: "/",
          },
        ],
      };
    case homeBanners.SET_CIV_BANNERS:
      return {
        en: [
          {
            img: "/images/homeBanners/placeholder.png",
            url: "https://google.com",
          },
        ],
        fr: [
          {
            img: "/images/homeBanners/civ/crbt/image1.jpeg",
            url: "https://zik.mtn.ci/crbt-web-portal/home",
          },
        ],
      };
    case homeBanners.SET_PLACEHOLDER_BANNERS:
      return {
        en: [
          {
            img: "/images/homeBanners/placeholder.png",
            url: "https://google.com",
          },
        ],
        fr: [
          {
            img: "/images/homeBanners/placeholder-fr.png",
            url: "https://google.com",
          },
        ],
      };
    default:
      return state;
  }
};

export default homeBannersReducer;