export const LANGUAGES = {
  gha: [
    {
      code: "en",
      name: "english",
    },
  ],
  zmb: [
    {
      code: "en",
      name: "english",
    },
  ],
  cmr: [
    {
      code: "fr",
      name: "french",
    },
    {
      code: "en",
      name: "english",
    },
  ],
  civ: [
    {
      code: "fr",
      name: "french",
    },
    {
      code: "en",
      name: "english",
    },
  ],
  cog: [
    {
      code: "fr",
      name: "french",
    },
    // {
    //   code: "en",
    //   name: "english",
    // },
  ],
  swz: [
    {
      code: "en",
      name: "english",
    },
  ],
};
